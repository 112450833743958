'use client';

import { useEffect } from 'react';

export interface ErrorProps {
  error: Error;
}

export default function Error({
  error,
}: ErrorProps) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <div>
      <h2>Erro inesperado</h2>
    </div>
  );
}